export function AccountStatusTag(status){
    let str = "";
    switch (parseInt(status)){
        case 1:
            str = "注册审核";
            break;
        case 2:
            str = "正常使用";
            break;
        case 3:
            str = "禁止使用";
            break;
    }
    return str;
}

export function AccountTypeTag(type){
    let str = "未知类型";
    switch (parseInt(type)){
        case 1:
            str = "管理员创建";
            break;
    }
    return str;
}